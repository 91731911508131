.carousel-container {
  width: 100%; /* Adjust width as needed */
  margin: auto;
}

.carousel-wrapper {
  width: 100%; /* Makes it take the full width of its parent */
  max-width: 100%; /* Set a max-width if needed */
  margin: auto; /* Center it */
}

.slick-slide {
  height: 800px; /* Set a fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide img {
  width: 100%; /* Make image fill container */
  height: 100%; /* Ensure it fills the height */
  object-fit: cover; /* Crop & fit */
}
